import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 引入vue-amap
import AMap from 'vue-amap';
Vue.use(AMap);
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)


// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德的key
  key: '8ea3fc1ea222697bfa66472f21f1be89',
  // 插件集合
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  uiVersion: '1.0.11' 
});
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
Vue.config.productionTip = false
import vueLazyload from 'vue-lazyload'
Vue.use(vueLazyload);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
