<template>
  <div class="footer" id="contact">
    <div class="container">
        <h2></h2>
        <ul>
          <li class="normal">
              <div class="normal-box">
                <img src="./phone.png" /> 
                <div class="content">
                    <p class="name mb">Phone</p>
                    <p class="txt">400-055-7088</p>
                </div>
              </div>
              <a class="link" href="https://mall.jd.com/index-10318488.html?from=pc" target="_blank" >
                  <img src="./jdlink.jpg" /> 
              </a>
              <a class="link" href="//lightmark.tmall.com/shop/view_shop.htm?spm=a230r.7195193.1997079397.14.2f774314Flks7m" target="_blank">
                <img src="./tmlink.jpg" /> 
              </a>
              <a class="link" href="//www.lightmarkjewelry.com/" target="_blank">
                <img src="./lmjlink.jpg" alt="lightmark" title="lightmark" />
              </a>
          </li>
          <li class="normal">
              <div class="normal-box">
                <img src="./address.png" /> 
                <div class="content">
                    <p class="name mb">Address</p>
                    <p class="txt mb increase">小白光宇航中心<br/>上海市黄浦区南京东路299号宏伊国际广场S-401/402/403</p>
                    <p class="txt mb increase">上海恒隆港汇广场店<br />上海市徐汇区虹桥路1号恒隆港汇广场B103I</p>
                    <p class="txt mb increase">北京朝阳合生汇店<br/>北京市朝阳区西大望路甲22号院1号楼合生汇购物中心B2层B2-06B</p>
                    <p class="txt mb increase">深圳前海万象店<br/>深圳市南山区桂湾四路169号万象前海B114</p>                   
                    <p class="txt mb increase">南京河西天街店<br/>南京市建邺区云龙山路与楠溪江东街交汇处龙湖·南京河西天街-1F-37</p>
                    <p class="txt mb increase">南京万象天地店<br/>南京市秦淮区中山南路666号的南京万象天地B1层B109号</p>
                    <p class="txt mb increase">杭州湖滨·in77银泰店<br/>杭州市湖滨·in77银泰D区地下1层B1016</p>
                    <p class="txt mb increase">温州万象城店<br/>温州市瓯海区温瑞大道999号万象城L236</p>
                    <p class="txt mb increase">宁波万象城店<br/>宁波市江北区清河路265号万象城B1层B124号</p>
                    <p class="txt mb increase">武汉万象城店<br/>武汉市江岸区建设大道和台北路交汇处的武汉万象城B108号</p>
                    <p class="txt mb increase">青岛万象城店<br/>青岛市市南区山东路6号华润万象城N105</p>
                    <p class="txt mb increase">天津万象城店<br/>天津市河西区乐园道9号华润万象城L1-115</p>
                    <p class="txt mb increase">沈阳皇城恒隆广场店<br/>沈阳市沈河区中街路128号皇城恒隆广场商城1层158号</p>
                    <p class="txt mb increase">长春万象城店<br/>长春市南关区人民大街3288号华润万象城B158</p>
                    <p class="txt mb increase">西安荟聚店<br/>西安市雁塔区子午大道6号荟聚中心L01层B034号</p>
                    <p class="txt mb increase">湖州银泰店<br/>湖州市吴兴区爱山街道东吴银泰百货一层1024号</p>
                </div>
              </div>
          </li>
          <li class="lite">
              <img src="./qr.png" /> 
              <a class="link" href="javascript:void(0);">
                <img src="./wxlink.jpg" /> 
              </a>
          </li>
        </ul>
    </div>
    <div class="notic">
      <a href="http://beian.miit.gov.cn/" rel="nofollow" target="_blank">粤ICP备20066885号</a >
    </div>
  </div>
</template>

<script>
export default {
  name: 'footapp',
  props: {
    
  }
}
</script>

<style scoped lang="stylus">
.footer{
  padding: 60px 0 0;
  width: 100%;
  background:#fff;
}
h2{
  height:22px;
  width 217px
  margin: 0 auto 45px;
  background:  url('./contact.png') 50% 0 no-repeat;
}
.link{
  width 100%;
  display block;
  margin-top: 32px;
  text-align center;
}
li{
    vertical-align: text-top;
}
ul{
  width 840px
  display flex
  margin: 0 auto
}
.lite{
  flex: 1
  display: inline-block;
  img{
    width 103px;
    height 117px;
    display block;
    margin: 0 auto;
  }
  .link img{
    width 194px;
    height 28px
    
  }
}
.normal{
  flex: 1
  margin: 0 70px 0 14px;
  display: inline-block;
  .normal-box {
    display: flex;
    align-items center;
    overflow: hidden;
  }
  img{
    width: 35px;
    height: 35px;
    display: inline-block;

  }
  .link img{
    width 194px;
    height 28px
    margin: 0 auto;
  }
  .content{
    flex: 1;
    color: #3e3a39;
    margin-left: 18px;
    .mb{
      margin-bottom: 10px;
    }
    .name{
      font-size: 14px;
    }
    .txt{
      font-size: 12px;
    }
    .increase{
      width: 385px;
    }
  }

}
.notic{
  text-align center;
  margin-top: 70px;
  margin-bottom: 20px;
  a{
    color : #b0b0b0;
    &:hover{
      color : #ff6700;
    }
  }
}
</style>
