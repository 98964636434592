import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      // 页面标题title
      title:'Light Mark,小白光,培育钻石,实验室钻石,培育宝石,全球可持续高级珠宝,婚戒裸钻定制',
    },
    component: () => import('../views/Home/index.vue')
  },
  {
    path: '/detail/list',
    name: 'Detail-List',
    meta: {
      // 页面标题title
      title:'Light Mark,小白光,培育钻石,实验室钻石,培育宝石,全球可持续高级珠宝,婚戒裸钻定制',
    },
    component: () => import('../views/DetailList/index.vue')
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    meta: {
      // 页面标题title
      title:'Light Mark,小白光,培育钻石,实验室钻石,培育宝石,全球可持续高级珠宝,婚戒裸钻定制',
    },
    component: () => import('../views/Detail/index.vue')
  },
  {
    path: '/star',
    name: 'Star-Product',
    meta: {
      // 页面标题title
      title:'Light Mark,小白光,培育钻石,实验室钻石,培育宝石,全球可持续高级珠宝,婚戒裸钻定制',
    },
    component: () => import('../views/StarProduct/index.vue')
  },
  {
    path: '/lml',
    name: 'Lml',
    meta: {
      // 页面标题title
      title:'Light Mark,小白光,培育钻石,实验室钻石,培育宝石,全球可持续高级珠宝,婚戒裸钻定制',
    },
    component: () => import('../views/Lml/index.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      // 页面标题title
      title:'Light Mark,小白光,培育钻石,实验室钻石,培育宝石,全球可持续高级珠宝,婚戒裸钻定制',
    },
    component: () => import('../views/About/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
