<template>
  <div class="header">
    <div class="container">
        <ul>
          <li>
            <router-link :to="{ name: 'Home'}"  :class="{'active': menuSelected == 'Home'}"  >首页</router-link>
          </li>
          <li><router-link :to="{ name: 'About'}"  :class="{'active': menuSelected == 'About'}"  >品牌介绍</router-link></li>
          <li><router-link :to="{ name: 'Star-Product'}" :class="{'active': menuSelected == 'Star-Product'}" >明星产品</router-link></li>
          <li><router-link :to="{ name: 'Lml'}" :class="{'active': menuSelected == 'Lml'}"  >Light Mark Lab</router-link></li>
          <li><router-link :to="{ name: 'Detail-List'}" :class="{'active': menuSelected == 'Detail' || menuSelected == 'Detail-List'}" >News List</router-link></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headapp',
  data(){
    return {
      menuSelected: 'Home'
    }
  },
  watch:{
    $route(to,from){
      this.menuSelected = to ? to.name : 'Home'
     
    }
  },
  
  mounted(){
   
    
  }
}
</script>

<style scoped lang="stylus">
.header{
  width: 100%;
}
li{
    display: inline-block;
    height: 58px;
    line-height: 58px;
    a{
      padding: 0 15px;
      display: block;
      line-height: 58px;
      font-size: 17px;
      color: #3e3a39;
      &:hover,&.active{
        background : #353e66;
        color : #fff;
      }
    }
}
</style>
