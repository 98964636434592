<template>
  <div id="app">
    <Header ></Header>
    <div class="box-body">
      
      <router-view />
    </div>
    
    <Footer ></Footer>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'app',
  components: {
    Header,
    Footer
  },
  mounted(){
    console.log('2211')
  }
}
</script>

<style lang="stylus">
html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, 
small,  strong, sub, sup, 
b,  i, 
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, 
figure,  section, summary,
time, audio, video 
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section 
	display: block;

body 
	line-height: 1;

ol, ul 
	list-style: none;

blockquote, q 
	quotes: none;

blockquote:before, blockquote:after,
q:before, q:after 
	content: '';
	content: none;

table 
	border-collapse: collapse;
	border-spacing: 0;

</style>
<style lang="stylus">

html,body
  min-height: 100%
  width: 100%
  margin: 0 auto
#app
  font-family 'Avenir', Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  min-height: 100%
  width: 100%
  font-size: 14px;
  margin: 0 auto
  color: #3e3a39;
.container
  width: 1048px
  margin: 0 auto
a 
  text-decoration: none;
</style>
